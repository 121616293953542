import React from "react";
import SEO from "../components/seo";
import opsio from "../images/logo_opsio.svg";
import certified from "../images/certified_professional.svg";
import { Link } from "gatsby";
import experience from "../images/rating.svg";
import quality from "../images/control-system.svg";
import awsPartener from "../images/aws-partner-badge.svg";
import Footer from "../components/footer";
import { useStaticQuery, graphql } from "gatsby";
import OpsioAWSPartnership from "../components/campaign/OpsioAWSPartnership";

const WindowsMigrationsOnAws = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLayout {
        edges {
          node {
            locale
            navbar {
              footer {
                heading
                contact {
                  address1
                  address2
                  email
                  phone
                }
                values {
                  COMPANY {
                    name
                    src
                  }
                  INDUSTRIES {
                    name
                    src
                  }
                  PLATFORMS {
                    name
                    src
                  }
                  RESOURCES {
                    name
                    src
                  }
                  SERVICES {
                    name
                    src
                  }
                  SERVICESAWS {
                    name
                    src
                  }
                  SERVICESAZURE {
                    name
                    src
                  }
                  SERVICESGCP {
                    name
                    src
                  }
                  TECHNOLOGIES {
                    name
                    src
                  }
                  TECHNOLOGIES2 {
                    name
                    src
                  }
                  CLOUDSOLUTIONS {
                    name
                    src
                  }
                  CLOUDPLATFORMS {
                    name
                    src
                  }
                  DATAAI {
                    name
                    src
                  }
                  SECURITYCOMPLIANCE {
                    name
                    src
                  }
                  CODECRAFTING {
                    name
                    src
                  }
                  WORK {
                    name
                    src
                  }
                  CONTACTUS {
                    name
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const bulletPointsWithDesc = [
    {
      title: "Migration Assessment",
      des: "We assess your existing Windows workloads and provide a detailed analysis of the migration process, identifying potential challenges and opportunities for optimization.",
    },
    {
      title: "Migration Planning and Execution",
      des: "We design a comprehensive migration plan, considering factors such as workload dependencies, data transfer, and downtime requirements. Our team then executes the migration with minimal disruption to your operations.",
    },
    {
      title: "Post-Migration Support",
      des: " We provide ongoing support and monitoring to ensure your Windows workloads are running smoothly on AWS. We optimize performance, manage updates and patches, and offer troubleshooting assistance.",
    },
  ];

  const bulletPointsWithDescription = [
    {
      title: "Managed Services",
      des: "If desired, we offer managed services to handle the day-to-day management and monitoring of your Windows workloads on AWS. This allows you to focus on your core business while we take care of the infrastructure.",
    },
    {
      title: "Application Compatibility Testing",
      des: "Migrating Windows workloads to AWS may involve compatibility challenges with certain applications. We conduct thorough compatibility testing to identify any potential issues and provide recommendations or modifications to ensure smooth operation in the AWS environment.",
    },
    {
      title: "Disaster Recovery Planning",
      des: "We help you develop a comprehensive disaster recovery plan for your Windows workloads on AWS. By leveraging AWS services such as Amazon S3 for data backup and AWS CloudFormation for infrastructure provisioning, we ensure that your business-critical applications are protected and can be quickly restored in the event of an unexpected outage or disaster.",
    },
  ];

  const rightSection = [
    {
      title: "Experience and Expertise",
      des: "Opsio brings a wealth of experience in successfully migrating Windows workloads to AWS. Our team of seasoned professionals has handled numerous migrations, gaining invaluable knowledge and expertise in overcoming challenges and delivering optimal results.",
    },
    {
      title: "AWS-Certified Professionals",
      des: " We take pride in our team of AWS-certified professionals who possess deep knowledge and understanding of the AWS platform. Our experts stay updated with the latest advancements, best practices, and industry standards, ensuring that your migration is executed with the highest level of competency and precision.",
    },
    {
      title: "Quality-driven Approach",
      des: "At Opsio, we prioritize quality at every phase of the migration process. From initial assessment to planning, execution, and post-migration support, we adhere to rigorous standards to ensure that your Windows workloads are seamlessly migrated to AWS while maintaining data integrity, security, and performance.",
    },
  ];

  const requirementsData = {
    btn: "Get started today",
    des: "Unlock the Full Potential of AWS for Your Windows Workloads.",
    title: "Tell us about your business requirement",
  };

  const partnershipData = {
    mainTitle: "Opsio and AWS: A Winning Partnership",
    intro:
      "When it comes to selecting a vendor for your workload migration to AWS, Opsio stands out as the preferred choice. Our partnership with AWS ensures a robust and reliable migration experience, backed by the credibility and support of one of the leading cloud service providers in the industry.",
    heading: "Advantages of choosing Opsio",
    advantages: [
      {
        title: "Guaranteed Experience and Certifications",
        des: "Opsio is a recognized and trusted AWS Partner with a proven track record of successful Windows workload migrations. Our partnership with AWS signifies our commitment to excellence, adherence to best practices, and continuous learning to deliver exceptional results for our clients.",
      },
      {
        title: "End-to-End Quality Assurance",
        des: "As an AWS-focused service provider, Opsio is committed to maintaining the highest quality standards throughout the migration process. Our collaboration with AWS ensures that we leverage AWS-recommended methodologies, tools, and resources to deliver a seamless migration experience that meets or exceeds industry standards.",
      },
    ],
    des1: "Partnering with Opsio for your Windows workload migration to AWS means you can rely on a team of experts who have been thoroughly vetted and recognized by AWS. We provide the expertise, certifications, and quality assurance needed to guide you through every phase of the migration process, ensuring a successful transition to the AWS cloud environment.",
    des2: "Ready to unlock the power of AWS for your Windows workloads? Contact us today to discuss your specific migration needs. Our team of experts is eager to assist you in streamlining your operations, reducing costs, and enhancing the scalability and flexibility of your IT infrastructure.",
  };

  const getFooter = data.allStrapiLayout.edges[0].node;

  return (
    <div>
      <SEO
        slug="windows-migrations-on-aws"
        locale={"en"}
        title="Windows Migrations on AWS - Seamless Transition to the Cloud | Opsio"
        description="Unlock the power of AWS for your Windows workloads with our expert migration services. Seamlessly transition your Windows applications and data to the cloud, leveraging the scalability, flexibility, and cost optimization of Amazon Web Services."
      />
      <div className="service_upper_class_campaign">
        <div
          className="serviceHeroSectionCampaignPage d-md-flex flex-md-column  px-md-5 px-4 pb-0 "
          id="heading"
        >
          <div className="mb-md-5 pb-md-5 mb-5  ">
            <img src={opsio} alt="logo" />
          </div>
          <div className="d-md-flex justify-content-md-between mb-5 pb-5">
            <div>
              <div className="serviceHeroSectionTitle text-uppercase text-md-start text-center m-0 mb-3 ">
                CLOUD MIGRATION SERVICES
              </div>
              <h1 className="serviceHeroSectionHeading roboto text-md-start text-center mx-0 mb-3">
                Transform Your
                <div> Windows Workloads with</div>
                <div style={{ color: "#58C9E8" }} className="m-0">
                  The Power Of AWS
                </div>
              </h1>
              <div className="serviceHeroSectionDescription text-md-start text-center  me-0 ms-0 my-4  campaignDescription open-sans">
                Our Cloud Migration Services empower businesses to seamlessly
                migrate their Windows workloads to Amazon Web Services (AWS),
                leveraging the scalability, flexibility, and cost optimization
                of the AWS cloud infrastructure.
              </div>

              <div className="opsBtnwrap d-flex justify-content-center justify-content-md-start">
                <Link className="opsBtnlink " to="#contact-us">
                  <p>Start Your Migration Journey </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ourApproachWhiteOfferings serviceHotspot">
        <div className="containerwrap">
          <div className="approchTitle ">Overview </div>
          <div className="approchHeading mb-0 roboto">
            Opsio: Your Trusted Partner for Windows Workload Migration to AWS
          </div>
          <div className="approchdescription mt-5 open-sans">
            Welcome to Opsio, your trusted partner for seamless Windows workload
            migration to Amazon Web Services (AWS). With our specialized
            expertise and extensive experience, we ensure a smooth and efficient
            transition while maximizing the benefits of AWS cloud infrastructure
            for your business.
          </div>
        </div>
      </div>
      <div className="processOpsioOfferings serviceHotspot py-5 ">
        <div className="mx-md-5 mx-3">
          <div className="prorcss_wrap w-100" id="contact-us">
            <div className="d-lg-flex justify-content-md-between">
              <div className="processRightSectionServices w-100">
                <div className="inner_process d-md-flex justify-content-md-center w-100">
                  <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                    {bulletPointsWithDesc &&
                      bulletPointsWithDesc.map((bulletPoint, index) => (
                        <div
                          className="processDetailsDesCampaign col-md-12 col-12"
                          key={index}
                        >
                          <div className="number">
                            <>{index < 9 ? `0${index + 1}` : `${index + 1}`}</>
                          </div>
                          <div className="process_content_title mb-3 open-sans">
                            {bulletPoint.title}
                          </div>
                          <div className="process_content_solution">
                            {bulletPoint.des}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="processRightSectionServices w-100">
                <div className="inner_process d-md-flex justify-content-md-center w-100">
                  <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                    {bulletPointsWithDescription &&
                      bulletPointsWithDescription.map((bulletPoint, index) => (
                        <div
                          className="processDetailsDesCampaign col-md-12 col-12"
                          key={index}
                        >
                          <div className="number">
                            <>{index < 9 ? `0${index + 4}` : `${index + 4}`}</>
                          </div>
                          <div className="process_content_title mb-3 open-sans">
                            {bulletPoint.title}
                          </div>
                          <div className="process_content_solution">
                            {bulletPoint.des}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutUs py-5">
        <div className="containerwrap-about d-md-flex align-items-start pt-5">
          <div className="aboutUsLeftSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle">ABOUT US</div>
            <div className="aboutUsDescription " style={{ color: "white" }}>
              Opsio is a cloud service company focusing on enabling great
              innovation and technology to help our customers meet their
              business goals and demands. Our experience and knowledge is
              applied to help you benefit from the scale and pace of innovation
              of global infrastructure across multiple platforms, technologies,
              and services.
            </div>
            <div
              className="aboutUsDescription mt-3 "
              style={{ color: "white" }}
            >
              By leveraging our unique managed cloud services portfolio, we help
              relieve your own resources from operational heavy-lifting,
              enabling your focus to be shifted towards core business
              development. Through the power of automation, modern technology,
              micro services and containerization we help drive efficiency for
              your business now and into the future.
            </div>
            <div className="aboutUslogoSection">
              <div className="aboutUslogoSectionTitle">
                <div className="title mb-2">
                  We are certified cloud experts in
                </div>
                <div className="brands">
                  <figure>
                    <div style={{ backgroundColor: "white", padding: "5px" }}>
                      <img
                        className="pt-2"
                        src={awsPartener}
                        alt="Amazon Web Services"
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUsRightSectionCampaign col-md-6 col-12">
            <div className="aboutUsTitle text-center open-sans-serif">
              Why Choose Opsio?
            </div>
            <div className=" d-md-flex justify-content-md-center d-flex flex-column flex-md-row gap-5 flex-wrap">
              {rightSection.map(({ title, des }, index) => (
                <div className="card px-4 align-items-md-center h-25">
                  <div className="mb-2">
                    {index === 0 && (
                      <img
                        src={experience}
                        alt={title}
                        width="70px"
                        height="60px"
                      />
                    )}
                    {index === 1 && (
                      <img
                        src={certified}
                        alt={title}
                        width="80px"
                        height="60px"
                      />
                    )}
                    {index === 2 && (
                      <img
                        src={quality}
                        alt={title}
                        width="60px"
                        height="60px"
                      />
                    )}
                  </div>
                  <div className="d-md-flex flex-md-column text-md-start text-center ms-md-4">
                    <div className="card-title" style={{ paddingTop: "12px" }}>
                      {title}
                    </div>
                    <div className="card-des" style={{ paddingBottom: "15px" }}>
                      {des}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <OpsioAWSPartnership data={partnershipData} />

      <Footer
        data={getFooter.navbar.footer}
        locale={getFooter.locale}
        requirements={requirementsData}
        hideCta={false}
        campaignLink={"#contact-us"}
      />
    </div>
  );
};

export default WindowsMigrationsOnAws;
